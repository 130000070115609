import { features } from '@config';
import _app from '@core_modules/theme/pages/_app';

/**
 * Import global css
 * */
import '@core/styles/flexboxgrid.min.css';
import '@core/styles/index.css';
import '@core/styles/mediaquery.css';
import '@core/styles/stylePhone.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

if (features.useCustomStyle) {
    // eslint-disable-next-line global-require
    require('@core/styles/custom.css');
    require('@core/styles/fonts.css');
}

export default _app;
